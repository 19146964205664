<template>
  <div class="py-6 px-2">
    <div style="margin-bottom: 2rem;">
      <p class="hero-title has-text-centered">SHIFT AWARD</p>
    </div>
    
    <div id="firebaseui-auth-container"></div>
  </div>
</template>

<script>
import { firebase } from '@/main'

export default {
  mounted() {
    const firebaseui = require('firebaseui')
    const ui = new firebaseui.auth.AuthUI(firebase.auth())
    const redirectTo = this.$route.query.redirect

    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          provider: 'saml.onelogin',
          providerName: "OneLogin",
          buttonColor: '#222',
          iconUrl: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/anonymous.png'
        },
        // {
        //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //   disableSignUp: {
        //     status: true
        //   },
        //   requireDisplayName: false
        // }
      ],
      signInSuccessUrl: redirectTo ? redirectTo : '/'
    })
  }
}
</script>

<style lang="scss" scoped>
.hero-title {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
}
 
#firebaseui-auth-container ::v-deep {
  .mdl-button--raised.mdl-button--colored {
    background-color: #00205b;
  }
}
</style>